/* src/index.css */

/* CSS Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Ensure html and body take full height */
html, body, #root {
  height: 100%;
}

/* Import Tailwind CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;
