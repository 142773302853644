/* Black background for the entire app */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');


.content {
  flex:1;
  padding: 20px;
  font-size: 1.2rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  color: #000;
}
