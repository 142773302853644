/* Global font settings */
* {
  font-family: 'Montserrat', sans-serif !important;
}

.designPartnerContainer {
  background-color: #000000;
  color: #ffffff;
  min-height: 100vh;
}

.contentWrapper {
  padding-top: 80px;
}

/* Enhanced Hero Section */
.heroSection {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 8rem 2rem;
  background: #000000;
  position: relative;
  overflow: hidden;
}

.mainTitle {
  font-size: clamp(2.5rem, 6vw, 5rem);
  font-weight: 800;
  margin-bottom: 2rem;
  line-height: 1.1;
  background: linear-gradient(to right, #ffffff, #06d1d1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  letter-spacing: -1px;
  animation: fadeInUp 1s ease-out;
}

.subtitle {
  font-size: clamp(1.2rem, 3vw, 2rem);
  font-weight: 500;
  opacity: 0.9;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.4;
  color: #06d1d1;
  animation: fadeInUp 1s ease-out 0.3s backwards;
}

/* Hero Animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Hero Background Effect */
.heroSection::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at center, rgba(6,209,209,0.1) 0%, rgba(0,0,0,0) 70%);
  animation: pulseGradient 8s ease-in-out infinite;
}

@keyframes pulseGradient {
  0%, 100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}

/* Section Styles */
.section {
  padding: 4rem 2rem;
  margin: 2rem 0;
  border-radius: 2rem;
  background: rgba(0, 104, 104, 0.1);
  border: 1px solid rgba(6, 209, 209, 0.2);
  backdrop-filter: blur(10px);
  position: relative;
  overflow: hidden;
}

.section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(0,104,104,0.1) 0%, rgba(6,209,209,0.1) 100%);
  pointer-events: none;
}

.sectionTitle {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
  background: linear-gradient(to right, #06d1d1, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Feature List */
.featureList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 3rem 0;
}

.featureItem {
  padding: 2rem;
  border-left: 4px solid #06d1d1;
  background: rgba(6, 209, 209, 0.1);
  border-radius: 0 1rem 1rem 0;
  transition: all 0.3s ease;
}

.featureItem:hover {
  transform: translateX(10px);
  background: rgba(6, 209, 209, 0.2);
}

.featureIcon {
  font-size: 2.5rem !important;
  color: #06d1d1 !important;
  margin-bottom: 1rem;
}

.featureTitle {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #ffffff;
}

/* Benefits Grid */
.benefitsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 3rem 0;
}

.benefitItem {
  padding: 2rem;
  background: rgba(6, 209, 209, 0.1);
  border-radius: 1rem;
  transition: all 0.3s ease;
  border: 1px solid rgba(6, 209, 209, 0.2);
}

.benefitItem:hover {
  transform: translateY(-5px);
  background: rgba(6, 209, 209, 0.2);
}

/* CTA Section */
.ctaSection {
  text-align: center;
  padding: 6rem 2rem;
  background: linear-gradient(135deg, rgba(0,104,104,0.3) 0%, rgba(6,209,209,0.3) 100%);
  margin: 4rem 0;
  border-radius: 2rem;
}

.ctaButton {
  background: linear-gradient(135deg, #006868 0%, #06d1d1 100%) !important;
  color: #ffffff !important;
  padding: 1.5rem 4rem !important;
  font-size: 1.2rem !important;
  font-weight: 600 !important;
  border-radius: 3rem !important;
  border: 2px solid rgba(255, 255, 255, 0.1) !important;
  transition: all 0.3s ease !important;
}

.ctaButton:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(6, 209, 209, 0.3) !important;
}

/* Animations */
@keyframes gradientFlow {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.heroSection {
  background-size: 200% 200%;
  animation: gradientFlow 15s ease infinite;
}

/* Enhanced Responsive Design */
@media (max-width: 768px) {
  .heroSection {
    min-height: 70vh;
    padding: 6rem 1rem;
  }

  .mainTitle {
    font-size: clamp(2rem, 5vw, 3.5rem);
    margin-bottom: 1.5rem;
  }

  .subtitle {
    font-size: clamp(1rem, 2.5vw, 1.5rem);
  }
  
  .section {
    padding: 2rem 1rem;
    margin: 1rem 0;
  }
  
  .featureList, .benefitsGrid {
    grid-template-columns: 1fr;
  }
  
  .featureItem, .benefitItem {
    padding: 1.5rem;
  }
  
  .ctaSection {
    padding: 3rem 1rem;
  }
}

/* Add these new styles for the image section */
.heroContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.heroText {
  flex: 1;
  text-align: left;
}

.heroImage {
  flex: 1;
  position: relative;
  height: 400px;
  width: 100%;
  max-width: 500px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(6, 209, 209, 0.2);
  animation: fadeInRight 1s ease-out 0.5s backwards;
}

.heroImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.heroImage:hover img {
  transform: scale(1.05);
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Update responsive design */
@media (max-width: 1024px) {
  .heroContent {
    flex-direction: column;
    gap: 2rem;
    text-align: center;
  }

  .heroText {
    text-align: center;
  }

  .heroImage {
    max-width: 100%;
    height: 300px;
  }
}